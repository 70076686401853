<template>
  <v-fade-transition mode="out-in">
    <div :class="[showNav ? 'nav-active' : '', 'app-body']">
      <router-view />
    </div>
  </v-fade-transition>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'MCMachelen',
    metaInfo: {
      title: '',
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - MC Machelen` : 'MC Machelen'
      },
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    computed: mapGetters({
      showNav: 'menu/menuActive',
    }),
  }
</script>

<style lang="scss">
  .extra-padding {
    padding-bottom: 96px !important;
    padding-top: 96px !important;

    @media screen and (max-width: 959px) {
      padding-top: 48px !important;
      padding-bottom: 48px !important;
    }
  }



</style>
