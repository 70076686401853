import * as types from '../mutation-types'

// state
export const state = {
  active: false,
}

// mutations
export const mutations = {
  [types.OPEN_MENU] (state) {
    state.active = !state.active
  },
  [types.CLOSE_MENU] (state) {
    state.active = false
  },
}

// getters
export const getters = {
  menuActive: state => state.active,
}
