// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '/',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: '/team',
          name: 'Team',
          component: () => import('@/views/team/Index.vue'),
          meta: { src: require('@/assets/hero-alt.jpg') },
        },
        {
          path: '/info',
          name: 'Info',
          component: () => import('@/views/info/Index.vue'),
          meta: { src: require('@/assets/hero-alt.jpg') },
        },
        {
          path: '/contact',
          name: 'Contact',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { src: require('@/assets/hero-alt.jpg') },
        },
        {
          path: '/praktijk',
          name: 'Pro',
          component: () => import('@/views/pro/Index.vue'),
          meta: { src: require('@/assets/hero-alt.jpg') },
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
