import Vue from 'vue'
import * as Contentful from 'contentful';

let client = null;

const ContentfulVue = {
  install(Vue, options) {
    client = Contentful.createClient({
      space: options.space,
      accessToken: options.accessToken,
      environment: options.environment || 'master',
    });

    Vue.prototype.$contentful = client;
  },
};

Vue.use(ContentfulVue, {
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN
});
