<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="300"
    >
      <v-card>
        <v-card-title class="text-h6">
          Wij zijn verhuisd!
        </v-card-title>
        <v-card-text>
            De groepspraktijk Machelen is verhuisd naar <br> Kerklaan 1 1830 Machelen!
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Sluiten
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'Popup',
    data () {
      return {
        dialog: true,
      }
    },
  }
</script>

<style scoped>

</style>
