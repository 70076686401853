<template>
  <v-alert
    v-model="alert"
    border="left"
    close-text="Sluiten"
    color="accent-4"
    dark
    dismissible
    text
  >
    <RichTextRenderer :document="message"/>
  </v-alert>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';

  export default {
    name: 'Alert',
    components: { RichTextRenderer },
    data () {
      return {
        alert: true,
        message: ''
      }
    },
    async created() {
      this.message = await this.getMessage();
    },
    methods: {
      getMessage() {
        return this.$contentful
          .getEntry('3FbDrvhaqCqqpqxkLRr9IH')
          .then((res) => {
            return res.fields.message;
          })
          .catch((error) => {
            throw new Error("Could not receive the data from Contentful!");
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
.v-alert__content{
  p {
    margin: 0;
  }
}
</style>
